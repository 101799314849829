import Link from 'next/link'
import { FC, useLayoutEffect, useRef, useState } from 'react'
import {
  MultiSelectTag,
  MultiSelectTagType,
} from '../multi-select-tag/multi-select-tag'
import style from './style-horizontal-scroll-wrapper.module.scss'
import { CheveronLeft } from '../icons/cheveron-left'
import { CheveronRight } from '../icons/cheveron-right'

export enum HorizontalScrollTypes {
  NORMAL,
  SOLO,
  OVERFLOW,
}

interface HorizontalScrollWrappersProps {
  /**
   * array of children
   */
  children: any[]
  /**
   * title for the scrollbar  j
   */
  title?: string
  /**
   * this will show filter
   */
  withFilter?: boolean
  showAllLink?: object
  sliderButton?: boolean
  products?: boolean
  colWidth?: string
  type: HorizontalScrollTypes
  scrollLength?: number
  smoothBorder?: string
  setPage?: any
  page?: any
  customScroll?: (cb: () => void) => void
  className?: any
  // headerhtml?: any
  image: any
  proplist?: any
}

export const StyleHorizontalScrollWrappers: FC<HorizontalScrollWrappersProps> = ({
  children,
  title,
  withFilter,
  showAllLink,
  sliderButton,
  products,
  colWidth,
  type,
  scrollLength,
  smoothBorder,
  customScroll,
  className,
  proplist,
  //headerhtml,
  image,
}) => {
  const typeClass = {
    [HorizontalScrollTypes.SOLO]: 'scroller--solo',
    [HorizontalScrollTypes.NORMAL]: '',
    [HorizontalScrollTypes.OVERFLOW]: 'scroller--overflow',
  }

  const wrapperEle: any = useRef(null)

  const scrollL = () => scrollLength || wrapperEle.current.clientWidth

  const scrollRight = () => {
    const distance = wrapperEle.current.scrollLeft + scrollL()
    if (!!customScroll) {
      customScroll(() => {
        wrapperEle.current.scrollTo({ left: distance, behavior: 'smooth' })
      })
    } else {
      wrapperEle.current.scrollTo({ left: distance, behavior: 'smooth' })
    }
  }

  const scrollLeft = () => {
    const distance = wrapperEle.current.scrollLeft - scrollL()
    wrapperEle.current.scrollTo({ left: distance, behavior: 'smooth' })
  }

  const horizontalScrollElement = useRef<HTMLDivElement | null | any>()

  const [eleWidth, setEleWidth] = useState(colWidth)
  useLayoutEffect(() => {
    if (!colWidth) {
      let width = 0
      if (window.innerWidth < 320) {
        width = horizontalScrollElement.current.scrollWidth / 4 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth > 320 && window.innerWidth < 484) {
        width = horizontalScrollElement.current.scrollWidth / 4 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth > 484 && window.innerWidth < 991) {
        width = horizontalScrollElement.current.scrollWidth / 5 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth >= 991 && window.innerWidth < 1200) {
        width = horizontalScrollElement.current.scrollWidth / 6 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth >= 1200 && window.innerWidth < 1600) {
        width = horizontalScrollElement.current.scrollWidth / 7 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth >= 1600) {
        width = horizontalScrollElement.current.scrollWidth / 10 - 16
        setEleWidth(`${width}px`)
      }
    }
    return () => {}
  }, [])

  return (
    <div
      ref={horizontalScrollElement}
      style={{
        borderRadius: `${smoothBorder}`,
      }}
      className={` ${className && style.scroller_pad} ${style.scroller} ${
        style[typeClass[type]]
      } ${products ? style.products : ''}`}
    >
      {type == HorizontalScrollTypes.NORMAL ? (
        // scrolled header
        <div className={`${className ? className : style.scroller__head}`}>
          {/* show title if title exists */}
          {title ? (
            // <div className={style.title}>
            //   <InnerHtml data={title} />
            // </div>
            <div className="flex align-middle items-center">
              {image ? (
                <img
                  height={40}
                  width={40}
                  src={image}
                  alt="image"
                  // loader={() => image}
                  // blurDataURL={image}
                  placeholder="blur"
                />
              ) : (
                ''
              )}
              <span className="font-semibold text-black text-lg pl-2 inline-block">
                <p
                  style={{
                    color: proplist?.style?.fontColor,
                    fontSize: proplist?.style?.fontSize,
                    fontWeight: proplist?.style?.fontStyle?.isBold && 'bold',
                    fontStyle: proplist?.style?.fontStyle?.isItalic && 'italic',
                    textDecoration: proplist?.style?.fontStyle?.isUnderLine
                      ? 'underline'
                      : proplist?.style?.fontStyle?.isStrikeThrough
                      ? 'line-through'
                      : '',
                  }}
                >
                  {title}
                </p>
              </span>
            </div>
          ) : (
            ''
          )}
          {/* {!!headerhtml ? headerhtml : ''} */}
          {/* provide space between items */}
          <div className={style.spacer} />

          {/* show tags if withFilter if option exits */}
          {withFilter ? (
            <MultiSelectTag
              name="price"
              items={['one', 'two', 'three']}
              onChange={(_va) => {}}
              type={MultiSelectTagType.Multiple}
            />
          ) : (
            ''
          )}

          {sliderButton ? (
            // show slider button is sliderButton option is true
            <div className={style.scroller__action}>
              {/* show all if showAllLink is provided */}
              {showAllLink ? (
                <Link href={showAllLink} className={style.show_all} passHref>
                  See all
                </Link>
              ) : (
                ''
              )}
              <button
                className={`${
                  className ? style.overflow_btn_left : style.btn_left
                }`}
                onClick={scrollLeft}
                aria-label="left"
              >
                <CheveronLeft />
              </button>
              <button
                className={`${
                  className ? style.overflow_btn_right : style.btn_right
                }`}
                onClick={scrollRight}
                aria-label="right"
              >
                <CheveronRight />
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}

      {type == HorizontalScrollTypes.SOLO ? (
        <button
          className={`${style.solo_btn} ${style['solo_btn--left']}`}
          onClick={scrollLeft}
        >
          <CheveronLeft />
        </button>
      ) : (
        ''
      )}
      {type == HorizontalScrollTypes.SOLO ||
      type == HorizontalScrollTypes.OVERFLOW ? (
        <button
          className={`${style.solo_btn} ${style['solo_btn--right']}`}
          onClick={scrollRight}
        >
          <CheveronRight />
        </button>
      ) : (
        ''
      )}

      <div
        className={`${style.scroller__wrapper} ${className}`}
        style={{
          gridTemplateColumns: `repeat(${children?.length}, ${
            eleWidth || '160px'
          })`,
        }}
        ref={wrapperEle}
      >
        {children}
      </div>
    </div>
  )
}
